<template>
    <div class="page-content">
      <b-row>
        <b-col md="10">
          <page-breadcrumb title="Season Management" class="mb-3" />
        </b-col>
      </b-row>
      <data-create-popup title="Create Season"
        :schema="create_schema" :default-data="{start_time: null, duration: null}"
        :create-data-fn="createItem" @create="$refs.group_list.getList()"
      />
      <data-table-ssr id="group_list" ref="group_list"
        :columns="fields" :get-list-fn="getList"
        :update_schema="update_schema" :edit-row-fn="updateItem"
      />
    </div>
  </template>
  
  <script>
  import service from "../service";
  const create_schema = [
    {
      cols: 12,
      fields: [
        { label: 'Start Time', field: 'start_time', input_type:"datetime", validate: { required: true } },
        { label: 'Duration (Week)', field: 'duration', validate: { required: true } },
      ]
    }
  ];
  const update_schema = [
  {
      cols: 12,
      fields: [
        { label: 'Start Time', field: 'start_time', input_type:"datetime", validate: { required: true } },
        { label: 'Duration (Week)', field: 'duration', validate: { required: true } },
      ]
    }
  ];
  const fields = [
    { label: 'Start Time', field: 'start_time', input_type:"datetime", validate: { required: true } },
    { label: 'Duration (Week)', field: 'duration', validate: { required: true } },
  ];
  export default {
  
    data() {
      return {
        create_schema,
        fields,
        update_schema,
      };
    },
  
    methods: {
      async getList({ limit, page, query }) {
        let response_data = await service.getList({
          query: JSON.stringify({ ...query }),
          page, limit
        });
        let list = [], total = 0;
        if (response_data) {
          list = response_data.list;
          total = response_data.total;
          this.total = total;
        }
        return { list, total };
      },
      async createItem(data) {
        let new_item = await service.create({ ...data });
        return new_item;
      },
      toEditPage({ _id }) {
        this.$router.push({ name: 'support-item-detail', params: { id: _id } });
      },
      async updateItem(row){
        await service.update({ data: JSON.stringify(row) });
      },
    },
  };
  </script>
  